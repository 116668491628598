import React from 'react';
import propTypes from 'prop-types';

const Article = ({ subheading = '', heading = '', content = '' }) => (
    <>
        <h3>{subheading}</h3>
        <h1>{heading}</h1>
        <p>{content}</p>
    </>
);
Article.propTypes = {
    subheading: propTypes.string.isRequired,
    heading: propTypes.string.isRequired,
    content: propTypes.string,
};
Article.defaultProps = {
    content: '',
};

export default Article;
