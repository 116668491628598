import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import Blocks from '../components/building-blocks';

const RenderBlock = ({ blockData }) =>
    (Blocks[blockData.type] || Blocks.default)(blockData);

export const CustomPageTemplate = ({ title, sections }) => (
    <>
        <div className="hero">
            <h1>{title}</h1>
        </div>
        <ul>
            {sections &&
                sections.map(section => (
                    <li key={section.type}>
                        {section.type}
                        <RenderBlock blockData={section} />
                    </li>
                ))}
        </ul>
    </>
);
CustomPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
        })
    ).isRequired,
};

const CustomPage = ({ data }) => {
    const { frontmatter } = data && data.markdownRemark;

    return (
        <Layout>
            <CustomPageTemplate
                title={frontmatter.title}
                sections={frontmatter.sections}
            />
        </Layout>
    );
};

CustomPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
                sections: PropTypes.arrayOf(PropTypes.object),
            }),
        }),
    }).isRequired,
};

export default CustomPage;

export const pageQuery = graphql`
    query CustomPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            frontmatter {
                title
                sections {
                    type
                    cards {
                        heading
                        image {
                            childImageSharp {
                                fixed: fixed(width: 300) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                        subheading
                    }
                    subheading
                    heading
                    label
                    link
                }
            }
        }
    }
`;
