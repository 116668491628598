import React from 'react';
import propTypes from 'prop-types';
import Img from 'gatsby-image';

const CaseStudies = ({ subheading = '', heading = '', cards = {} }) => (
    <>
        <h3>{subheading}</h3>
        <h1>{heading}</h1>
        {cards &&
            cards.map(card => (
                <div key={card.heading}>
                    <h4>{card.subheading}</h4>
                    <h2>{card.heading}</h2>
                    <Img
                        alt={`Case study ${card.heading}`}
                        fixed={card.image.childImageSharp.fixed}
                    />
                </div>
            ))}
    </>
);

CaseStudies.propTypes = {
    subheading: propTypes.string.isRequired,
    heading: propTypes.string.isRequired,
    cards: propTypes.node.isRequired,
};

export default CaseStudies;
